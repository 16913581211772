import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
// import BackgroundImage from 'gatsby-background-image'

import { FluidImage, BackgroundFluidImage } from "@components/Image"
import { Svg } from "@components/Svg"

import {useTranslation} from 'react-i18next'
// import Link from "@components/Link"
// import { Pc, Sp } from "@hooks/useMediaQuery"
import { useURL } from '@hooks/useURL'

import css from "@css/components/blocks/global/FooterSNS.module.styl"

// import TwitterSVG from '@images/GlobalFooterSNS__icn__twitter.svg'
// import YoutubeSVG from '@images/GlobalFooterSNS__icn__youtube.svg'
// import TwitterButtonSVG from '@images/GlobalFooterSNS__btn__twitter.svg'
// import YoutubeButtonSVG from '@images/GlobalFooterSNS__btn__youtube.svg'


export default function GlobalFooterSNS()
{
    const {t} = useTranslation()
    const q = useStaticQuery(graphql`
        query GlobalFooterSNSQuery {
            miraicomachiImg : file(relativePath: { eq: "GlobalFooterSNS__img__miraicomachi@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 790) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bgImg : file(relativePath: { eq: "GlobalFooterSNS__img__bg@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            twitterImg : file(relativePath: { eq: "GlobalFooterSNS__icn__twitter@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            youtubeImg : file(relativePath: { eq: "GlobalFooterSNS__icn__youtube@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 24) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            twitter : file(relativePath: { eq: "GlobalFooterSNS__icn__twitter.svg" }) {
                publicURL
            }
            youtube : file(relativePath: { eq: "GlobalFooterSNS__icn__youtube.svg" }) {
                publicURL
            }
            twitterButton : file(relativePath: { eq: "GlobalFooterSNS__btn__twitter.svg" }) {
                publicURL
            }
            youtubeButton : file(relativePath: { eq: "GlobalFooterSNS__btn__youtube.svg" }) {
                publicURL
            }
        }
    `)
    const [mounted, isMounted] = React.useState(false)
    const urls = useURL()

    React.useEffect(()=>{
        isMounted(true)
    },[mounted])

    return(
        <div className={css.container}>
            <div className={css.bgWrapper}>
                <BackgroundFluidImage className={css.bg} data={q.bgImg} loading="eager" />
            </div>
            <figure className={css.miraicomachi}>
                <div className={css.inner1}>
                    <div className={css.inner2}>
                        <FluidImage data={q.miraicomachiImg} loading="eager"/>
                    </div>
                </div>
            </figure>
            <div className={css.inner}>
                <div className={css.card}>
                    <h3 className={css.title} dangerouslySetInnerHTML={{__html: t("footerSns__title1")}} />
                    <div className={css.pc}>
                        <div className={css.buttons}>
                            <a className={css.twitterButton} href={urls.twitter} target="_blank">
                                <figure className={css.icon}>
                                    <Svg src={q.twitter.publicURL} title="twitter"/>
                                </figure>
                                {t("footerSns__twitterButton")}
                            </a>
                            <a className={css.youtubeButton} href={urls.youtube} target="_blank">
                                <figure className={css.icon}>
                                    <Svg src={q.youtube.publicURL} title="youtube"/>
                                </figure>
                                {t("footerSns__youtubeButton")}
                            </a>
                        </div>
                    </div>
                    <div className={css.sp}>
                        <div className={css.sp}>
                            <div className={css.buttons}>
                                <a className={css.button} href={urls.twitter} target="_blank">
                                    <Svg src={q.twitterButton.publicURL} alt="twitter"/>
                                </a>
                                <a className={css.button} href={urls.youtube} target="_blank">
                                    <Svg src={q.youtubeButton.publicURL} alt="youtube"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.trademarks}>
                    {t('footer__trademark')}
                </div>
            </div>
        </div>
     )
}
