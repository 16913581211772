import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { FluidImage } from "@components/Image"
import { Svg } from "@components/Svg"
import Link from "@components/Link"
import {useTranslation} from 'react-i18next'

import { Pc, Sp } from "@hooks/useMediaQuery"

import { useFooterLink, useURL } from '@hooks/useURL'

import css from "@css/components/blocks/global/Footer.module.styl"
// import LogoSvg from "@images/Global__logo__miraicomachi.svg"

export default function GlobalFooter()
{
    const q = useStaticQuery(graphql`
        query GlobalFooterQuery {
            bandainamco : file(relativePath: { eq: "GlobalFooter__img__bandainamco@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 267) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logo : file(relativePath: { eq: "Global__logo__miraicomachi.svg" }) {
                publicURL
            }
        }
    `)
    const footerLink = useFooterLink()
    const urls = useURL()
    const { t } = useTranslation()

    return(
        <div className={css.container}>
            <div className={css.inner}>
                <nav className={css.nav_left}>
                    <ul className={css.list}>
                        <li className={css.logo}>
                            {/* <LogoSvg/> */}
                            <Svg src={q.logo.publicURL} title="ミライ小町 - MIRAI KOMACHI -"/>
                        </li>
                        <Pc>
                        {
                            footerLink.map(({ label, to }, i)=>{
                                return(
                                    <li className={css.item} key={i}><a href={to} target="_blank">{label}</a></li>
                                )
                            })
                        }
                        </Pc>
                    </ul>
                </nav>

                <div className={css.nav_right}>
                    <ul className={css.list}>
                        <li className={css.item}>
                            <Link to={urls.bandainamuco} className={css.bandainamuco}>
                                <FluidImage data={q.bandainamco} alt="株式会社バンダイナムコ研究所 -BANDAI NUMCO Research inc.-"/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
     )
}
