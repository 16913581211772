import React from "react"
import { ReactSVG } from 'react-svg'

function Svg({ src, title, desc }) {

    return (
        <ReactSVG
            src={src}
            role="img"
            aria-label={title}
            beforeInjection={(svg) => {
                if( desc ){
                    const descElement = document.createElementNS(
                        'http://www.w3.org/2000/svg',
                        'desc'
                    )
                    descElement.innerHTML = desc
                    svg.prepend(descElement)
                }
                if( title ){
                    const titleElement = document.createElementNS(
                        'http://www.w3.org/2000/svg',
                        'title'
                    )
                    titleElement.innerHTML = title
                    svg.prepend(titleElement)
                }
            }}
        />
    )
}

export { Svg }