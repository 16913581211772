
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
	lang,
	title,
	description,
	meta,
	image,
	pathname,
	keywords,
	isArticle,
}){
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						local
						title
						title_en
						desc
						desc_en
						site_url
						image
						sns_twitter_id
						google_site_verification
						keywords
						keywords_en
					}
				}
			}
		`
	)

	const d = site.siteMetadata
	const seo = {
		title: title ? `${title} | ${d.title} ${d.title_en}` : `${d.title} ${d.title_en}`,
		description: description || `${d.desc} ${d.desc_en}`,
		image: `${d.site_url}${ image || d.image}`,
		url: `${d.site_url}${ pathname || "/"}`,
		twitterId: d.sns_twitter_id,
		googleSiteVerification: d.google_site_verification,
		keywords: keywords || `${d.keywords},${d.keywords_en}`
	}

	return (
		<React.Fragment>
			<Helmet title={seo.title}>
				<meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover"/>
				<meta name="apple-mobile-web-app-capable" content="yes"/>{/*ホーム画面から起動時にアプリのようにする*/}
				<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent"/>{/*ノッチまわりの表示*/}

				<meta http-equiv="X-UA-Compatible" content="IE=edge"/>

				<meta name="description" content={seo.description} />
				{ seo.googleSiteVerification &&
					<meta name="google-site-verification" content={seo.googleSiteVerification}/>
				}
				<meta name="image" content={seo.image} />

				{ d.local &&
					<meta property="og:locale" content={d.local} />
				}
				<meta property="og:type" content={ isArticle ? 'article' : 'website'} />
				{ seo.url &&
					<meta property="og:url" content={seo.url} />
				}
				{ seo.title &&
					<meta property="og:title" content={seo.title} />
				}
				{ seo.title &&
					<meta property="og:site_name" content={seo.title} />
				}
				{seo.description && (
					<meta property="og:description" content={seo.description} />
				)}
				{seo.image &&
					<meta property="og:image" content={seo.image} />
				}
				<meta name="twitter:card" content="summary_large_image" />
				{ seo.twitterId && (
					<meta name="twitter:creator" content={seo.twitterId} />
				)}
				{seo.title &&
					<meta name="twitter:title" content={seo.title} />
				}
				{seo.description && (
					<meta name="twitter:description" content={seo.description} />
				)}
				{seo.image &&
					<meta name="twitter:image" content={seo.image} />
				}
			</Helmet>
		</React.Fragment>
	)
}

export default SEO
